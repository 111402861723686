import { lazy } from "react";
const FirmExist = lazy(() => import("views/login/firmExist"));
const SignUpSuccess = lazy(() => import("views/login/signupSuccess"));
const Activity = lazy(() => import("views/Activity/activity"));
const Signup = lazy(() => import("views/signup/signup"));
const UserExists = lazy(() => import("views/signup/UserExists"));
const ContactTag = lazy(() => import("views/contact/contactTag"));
const Dashboard = lazy(() => import("views/dashboard/Dashboard"));
const ManageContact = lazy(() => import("views/contact/manageContact"));
const CustomDeadline = lazy(() => import("views/deadline/CustomDeadline"));
const Deadline = lazy(() => import("views/deadline/Deadline"));
const Settings = lazy(() => import("views/firm/Settings"));
const Help = lazy(() => import("views/help/Help"));
const ConfigureTeams = lazy(() => import("views/matter/configureTeams"));
const CopyTemplate = lazy(() => import("views/matter/CopyTemplate"));
const CreateMatter = lazy(() => import("views/matter/createMatter"));
const Notifications = lazy(() => import("views/notifications/notifications"));
const ManageGroup = lazy(() => import("views/matter/ManageGroup"));
const MatterWithNoGroup = lazy(() => import("views/matter/MatterWithNoGroup"));
const MissingGroup = lazy(() => import("views/matter/MissingGroup"));
const NamingPolicy = lazy(() => import("views/matter/namingPolicy"));
const ConfigureMeeting = lazy(() => import("views/meetings/configureMeeting"));
const PushNotification = lazy(
  () => import("views/notification/PushNotification")
);
const Profile = lazy(() => import("views/profile/Profile"));
const CaseSharingReport = lazy(() => import("views/report/caseSharingReport"));
const DateRangeReport = lazy(() => import("views/report/DateRangeReport"));
const Download = lazy(() => import("views/report/Download"));
const ModifiedDeadlineReport = lazy(
  () => import("views/report/ModifiedDeadlineReport")
);
const RuleChangeReport = lazy(() => import("views/report/RuleChangeReport"));
const Tracker = lazy(() => import("views/tracker/tracker"));
const UserDashboard = lazy(
  () => import("views/userDetails/UserDasboard/UserDasboard")
);
const User = lazy(() => import("views/userDetails/users"));
const ViewUser = lazy(
  () => import("views/userDetails/viewUserDetails/viewUser")
);
const Plugin = lazy(() => import("views/plugins/plugin"));
const Login = lazy(() => import("views/login/login"));
const LoginSuccess = lazy(() => import("views/login/loginSuccess"));
const MsLoginSuccess = lazy(() => import("views/signup/msLoginSucess"));
const UnAuthorizePage = lazy(() => import("views/login/UnAuthorizePage"));
const TableView = lazy(() => import("views/login/TableView"));
const Matter = lazy(() => import("views/matter/matter"));
const TeamTemplates = lazy(() => import("views/teams/TeamTemplates"));
const MatterDashboard = lazy(
  () => import("views/matter/dashboard/matterDashboard")
);
const ResourceNotFound = lazy(() => import("shared/ResourceNotFound"));
const WebpageLink = lazy(() => import("views/netDocuments/WebpageLink"));
const MatterLinks = lazy(() => import("views/matter/matterLinks"));
const NetDocsMatters = lazy(() => import("views/netDocuments/NetDocsMatters"));
const ClauseBank = lazy(() => import("views/clauseBank/clauseBank"));
const ViewTemplate = lazy(() => import("views/clauseBank/ViewTemplate"));
const ReportScheduler = lazy(() => import("views/report/ReportScheduler"));
const AuditDeadline = lazy(() => import("views/DeadlineAudit/DeadlineAudit"));
const MatterTag = lazy(() => import("views/matter/matterTag/matterTag"));
const AiTag = lazy(() => import("views/ai/aiTag/aiTag"));
const MatterArchiveReport = lazy(() => import("views/report/MatterArchiveReport"));
const FindEvents = lazy(() => import("views/report/FindEvents"));
const DuplicateEventInCalendar = lazy(() => import("views/report/DuplicateEventInCalendar"));
const CancelledEventsInCalendar = lazy(() => import("views/report/CancelledEventInCalendar"));
const TaskReport = lazy(() => import("views/task/taskReport"));
const AuditUserCalander = lazy(() => import("views/report/AuditUserCalander"));
const PowerShells = lazy(() => import("views/powerShells/powerShells"));
const Support = lazy(() => import("views/notifications/support"));
const SignupPowerShells = lazy(() => import("views/powerShells/signupPowerShells"));
const PlayGroundDashboard = lazy(() => import("views/ai/playgroundDashboard"));
const AIIndex = lazy(() => import("views/ai/aiIndex"));
const KeyVaultSetting = lazy(() => import("views/keyVaultSetting/keyVaultSetting"));
const AIDocumentManager = lazy(() => import("views/ai/uploadFile/aiDocumentManager"));
const MatterReport = lazy(() => import("views/report/MatterReport"));
const IManageAssociatedMatters = lazy(() => import("views/imanage/associatedMatters"));
const AuditUser = lazy(() => import("views/userDetails/auditUser/auditUser"));
const AuditFirmDeadlines = lazy(() => import("views/report/AuditFirmDeadlines/AuditFirmDeadlines"));
const UserTag = lazy(() => import("views/userDetails/userTag/userTag"));

const routes: IRoute[] = [
  {
    path: "/",
    name: "Home Page",
    component: Dashboard,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/firm/profile",
    name: "Firm Profile",
    component: Profile,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/firm/settings",
    exact: true,
    name: "Firm Settings",
    component: Settings,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/ai/playground",
    exact: true,
    name: "Play ground",
    component: PlayGroundDashboard,
    isProtected: true,
    withNavigation: true,
  },
  // matter/m-id/dashboard
  {
    path: "/ai/playground/:promptID/edit",
    exact: true,
    name: "Play ground Edit",
    component: PlayGroundDashboard,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/ai/tags",
    exact: true,
    name: "Ai Tag",
    component: AiTag,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/ai/index",
    exact: true,
    name: "AI Index",
    component: AIIndex,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/ai/openAISetting",
    name: "OpenAISetting",
    component: KeyVaultSetting,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/ai/documentManager",
    name: "AIDocumentManager",
    component: AIDocumentManager,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },

  {
    path: "/clause-bank",
    exact: true,
    name: "Clause Bank",
    component: ClauseBank,
    isProtected: true,
    withNavigation: true,
  }, {
    path: "/clause-bank/templates/:clauseID",
    exact: true,
    name: "View Template",
    component: ViewTemplate,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/firm/policy",
    exact: true,
    name: "Naming Policy",
    component: NamingPolicy,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/matter/tags",
    exact: true,
    name: "Matter Tag",
    component: MatterTag,
    isProtected: true,
    withNavigation: true,
  },
  // matter/m-id/dashboard
  {
    path: "/matter/:matterId/dashboard",
    exact: true,
    name: "Matter Dashboard",
    component: MatterDashboard,
    isProtected: true,
    withNavigation: true,
  },

  {
    path: "/matter/create-matter",
    exact: true,
    name: "Create Matter",
    component: CreateMatter,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/matter",
    exact: true,
    name: "Manage Matter",
    component: Matter,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/notification/app-notification",
    exact: true,
    name: "App Notification",
    component: Notifications,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/notification/whats-new",
    exact: true,
    name: "Whats New",
    component: Notifications,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/notification/onboarding",
    exact: true,
    name: "Onboarding",
    component: Notifications,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/notification/support",
    exact: true,
    name: "Support",
    // component: Support,
    component: Notifications,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/notification/rule-update",
    exact: true,
    name: "Rule Update",
    component: Notifications,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/notification/training",
    exact: true,
    name: "Training",
    component: Notifications,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/notification/admin-release-notes",
    exact: true,
    name: "Admin Release Notes",
    component: Notifications,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/notification/powershell",
    exact: true,
    name: "PowerShell",
    component: PowerShells,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/matter/:id/links",
    exact: true,
    name: "Matter Links",
    component: MatterLinks,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/group/manage-group",
    exact: true,
    name: "Manage Group",
    component: ManageGroup,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/matter/:matterID/deadlines",
    exact: false,
    name: "Matter Manage Deadlines",
    component: Deadline,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/matter/custom-deadline",
    exact: true,
    name: "Matter Customize Deadlines",
    component: CustomDeadline,
    isProtected: true,
    withNavigation: true,
  },

  {
    path: "/group/missing-groups",
    exact: true,
    name: "Group Missing Groups",
    component: MissingGroup,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/group/check-matter",
    exact: true,
    name: "Group Matter with no Group",
    component: MatterWithNoGroup,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/teams",
    exact: true,
    name: "Configure Teams",
    component: ConfigureTeams,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/teams/templates",
    exact: true,
    name: "Configure Teams",
    component: TeamTemplates,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/matter/copy-template",
    exact: true,
    name: "Copy Template",
    component: CopyTemplate,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/report/report-scheduler",
    exact: true,
    name: "Report Scheduler",
    component: ReportScheduler,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/report/matter-report",
    exact: true,
    name: "Matter Report",
    component: MatterReport,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/teams/report-scheduler",
    exact: true,
    name: "Teams Report Scheduler",
    component: ReportScheduler,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/report/audit-user-calander",
    exact: true,
    name: "Audit User Calendar",
    component: AuditUserCalander,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/event/find-events",
    exact: true,
    name: "Find Events",
    component: FindEvents,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/event/duplicate-events",
    exact: true,
    name: "Duplicate Events",
    component: DuplicateEventInCalendar,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/event/cancelled-events",
    exact: true,
    name: "Cancelled Events",
    component: CancelledEventsInCalendar,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/matter/task",
    exact: true,
    name: "Task",
    component: TaskReport,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/report/download",
    exact: true,
    name: "Report Download",
    component: Download,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/report/archive-report",
    exact: true,
    name: "Matter Archive Report",
    component: MatterArchiveReport,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/report/date-range",
    exact: true,
    name: "Report  DateRange",
    component: DateRangeReport,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/report/rule-change",
    exact: true,
    name: "Report Rule Change",
    component: RuleChangeReport,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/activity/deadline-audit",
    exact: true,
    name: "Audit Deadline",
    component: AuditDeadline,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/report/case-sharing",
    exact: true,
    name: "Report Case Sharing",
    component: CaseSharingReport,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/report/modified-deadline",
    exact: true,
    name: "Report Modified Deadline",
    component: ModifiedDeadlineReport,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/report/audit-firm-deadlines",
    exact: true,
    name: "Audit Firm Deadlines",
    component: AuditFirmDeadlines,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/help",
    exact: true,
    name: "Help",
    component: Help,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/contact/tags",
    exact: true,
    name: "Contact Tag",
    component: ContactTag,
    isProtected: true,
    withNavigation: true,
  },

  {
    path: "/contact",
    exact: true,
    name: "Manage Contacts",
    component: ManageContact,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/netdocuments/webpagelink",
    name: "Webpage Link",
    component: WebpageLink,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/netdocuments/ndmatters",
    name: "NetDocs Matter",
    component: NetDocsMatters,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },

  {
    path: "/imanage/associated-matters",
    exact: true,
    name: "IManage Matters",
    component: IManageAssociatedMatters,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/users",
    exact: true,
    name: "Users",
    component: User,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/user/tags",
    exact: true,
    name: "User Tag",
    component: UserTag,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/user/showView/:userID",
    exact: true,
    name: "Show User View",
    component: ViewUser,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/users/:userID/dashboard",
    exact: true,
    name: "Details",
    component: UserDashboard,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/users/audit-user",
    exact: true,
    name: "Audit User",
    component: AuditUser,
    isProtected: true,
    withNavigation: true,
  },

  {
    path: "/teams/meeting",
    exact: true,
    name: "Configure Meeting",
    component: ConfigureMeeting,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/tracker",
    exact: true,
    name: "Timesheet",
    component: Tracker,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/firm/plugins",
    exact: true,
    name: "Plugins",
    component: Plugin,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/activity/notifications",
    exact: true,
    name: "Push Notification",
    component: PushNotification,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/users/activity",
    exact: true,
    name: "Activity",
    component: Activity,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/activity",
    exact: true,
    name: "Activity",
    component: Activity,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: Login,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/signup-powershell",
    exact: true,
    name: "Signup Powershell",
    component: SignupPowerShells,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/loginSuccess",
    exact: true,
    name: "Login Success",
    component: LoginSuccess,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/SignUpSuccess",
    exact: true,
    name: "Signup Success",
    component: SignUpSuccess,
    isProtected: false,
    withNavigation: false,
  },

  {
    path: "/signup-details",
    exact: true,
    name: "signup",
    component: Signup,
    isProtected: false,
    withNavigation: false,
  },

  {
    path: "/msLoginSuccess",
    exact: true,
    name: "Login Success",
    component: MsLoginSuccess,
    isProtected: false,
    withNavigation: false,
  },

  {
    path: "/UserExists",
    exact: true,
    name: "User Exists",
    component: UserExists,
    isProtected: false,
    withNavigation: false,
  },

  {
    path: "/unauthorized",
    exact: true,
    name: "Unauthorize Page",
    component: UnAuthorizePage,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/firmexist",
    exact: true,
    name: "Firm Exist",
    component: FirmExist,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/table",
    exact: true,
    name: "Table",
    component: TableView,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "*",
    exact: true,
    name: "Table",
    component: ResourceNotFound,
    isProtected: false,
    withNavigation: false,
  },
];

export default routes;
