import { CFade } from "@coreui/react";
import { FC, createContext, useEffect, useState } from "react";
import commonApi from "services/apis/common.api";
import cookieService from "services/cookie.service";
import Loader from "views/widgets/loader";
import { constants } from "script/constant";
import api from "services/api.service";
export interface IAuth {
  user: IAuthUser;
  plugins: IPlugin[];
  appPermission: IAppPermission;
  userSettings: IUserSetting;
  onLogin: () => void;
  onLogout: () => void;
  onSignup: () => void;
  getUserSettings: () => void;
  isAuthenticated: boolean;
  isAIEnabled: boolean;
  isCreateMatterEnabled: boolean;
}

const authContentDefaults: IAuth = {
  user: {
    botConversationReference: null,
    hasAdminPortalAccess: false,
    isPrivateUser: false,
    sharePointSiteName: null,
    isGroupLinkEnabled: false,
    isAttachGroupEnabled: false,
    isCreateGroupEnabled: false,
    office365_TenantID: null,
    office365_UserObjectID: null,
    office365_UserUniqueID: null,
    isDeleted: false,
    isActive: false,
    isNew: false,
    userPrefTimeZone: null,
    userType: null,
    logo: null,
    channelID: 0,
    appID: 0,
    payStatus: 0,
    firmEmail: null,
    firmName: "",
    firmID: 0,
    userImage: null,
    userUPN: null,
    userEmail: "",
    userName: "",
    userID: 0,
    caseRename: null,
    isDocketingUser: false,
    stateAbr: null,
    userTimeZoneName: "",
    stateName: "",
  },
  plugins: [],
  userSettings: {
    isPrivateUser: false,
    hasAdminPortalAccess: false,
    hasThirdPartyDoc: false,
    privateUserCount: 0,
    isEtickleReminder: false,
    canCreateMatter: false,
    canAddMiscAppointment: false,
    canCloseMatter: false,
    canSaveEmail: false,
    canCalculateDeadlines: false,
    canViewFirmMatters: false,
    canEditDeadline: false,
    canDeleteDeadline: false,
    canShareCalendarExternally: false,
    canViewDocumentFolder: false,
    canViewSharedInbox: false,
    canViewOneNotes: false,
    canViewCalendar: false,
    createMatterValidation: false,
    documentFolderUrl: "",
    canViewContacts: false,
    canAddContacts: false,
    canCopyTemplatesInTeams: false,
    canCopyTemplates: false,
    canCopyTemplatesInOutlook: false,
    canAddContactTags: false,
    canCreateVirtualMeetings: false,
    firmCanCreateVirtualMeetings: false,
    canManageContacts: false,
    canSaveEmailInGroup: false,
    isSharepointSaveEmail: false,
    canCreateTeam: false,
    canCreateTeamWithMatter: false,
    matterFilesPreference: false,
    addPrefixSuffixToGroupName: false,
    addPrefixSuffixToGroupEmail: false,
    addPrefixSuffixToLTBMatterName: false,
    isGroupOwner: false,
    canShareFile: false,
    canViewTimeSheets: false,
    allowCentralDocketingUserAsGroupAdmin: false,
    allowFirmUsersToCreateMSTeam: false,
    showDelveLink: false,
    autoDeleteCancelledEvent: false,
    isLongDescriptionDeadlineTypeEnabled: false,
    isPlannerEnabled: false,
    canViewSwitchMatterCard: false,
    canViewUploadDocument: false,
    canViewSaveEmail: false,
    canViewEmails: false,
    canViewMatterContacts : false,
  },
  appPermission: {
    isMSGraphAppOnlyConsented: true,
    isMSGraphAppOnlyEnabled: true,
  },
  onLogin: () => { },
  onLogout: () => { },
  onSignup: () => { },
  getUserSettings: () => { },
  isAuthenticated: false,
  isAIEnabled: true,
  isCreateMatterEnabled: true,
};

export const authContext = createContext<IAuth>(authContentDefaults);

const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<IAuthUser>(authContentDefaults.user);
  const [userSettings, setSettings] = useState<IUserSetting>(authContentDefaults.userSettings);
  const [plugins, setPlugins] = useState<IPlugin[]>(authContentDefaults.plugins);
  const [isLoading, setLoader] = useState<boolean>(true);
  const [isAuthenticated, setAuthentication] = useState<boolean>(
    authContentDefaults.isAuthenticated
  );
  const [appPermission, setPermission] = useState<IAppPermission>(authContentDefaults.appPermission);

  const [isAIEnabled, setAIEnabled] = useState<boolean>(authContentDefaults.isAIEnabled);
  const [isCreateMatterEnabled, setCreateMatterEnabled] = useState<boolean>(authContentDefaults.isCreateMatterEnabled);
  useEffect(() => {
    fetchApiandPlugins();
  }, []);
  const fetchApiandPlugins = () => {
    setLoader(true);
    try {
      getSettings().then((res) => {
        if (res) {
          getAppConsent();
          fetchInitialApis();
          loadPlugin();
        }
      });

    } catch (error) {

    }

  }
  const getAppConsent = () => {
    api
      .get(constants.GET_APP_PERMISSION)
      .then((response) => {
        setPermission(response.data);
      }).catch((error: XMLHttpRequest) => {
        if (error.status === 401) {
          setAuthentication(false);
        }
        setLoader(false);
      })
  }
  const getSettings = async () => {
    return await commonApi.fetchSettings().then((res) => {
      setSettings(res.firmSettings);
      setAIEnabled(res.isAIEnabled);
      setCreateMatterEnabled(res.isCreateMatterEnabled);
      return res;
    }).catch((error: XMLHttpRequest) => {
      if (error.status === 401) {
        setAuthentication(false);
      }
      setLoader(false);
    })
  }

  const getUserHandler = async () => {
    await commonApi
      .fetchUser()
      .then((res) => {
        setAuthentication(true);
        setUser(res);
      })
      .catch((error: XMLHttpRequest) => {
        if (error.status === 401) {
          setAuthentication(false);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  }
  const fetchInitialApis = () => {
    getUserHandler();
  };

  const loginHandler = () => fetchApiandPlugins();

  const logoutHandler = () => {
    cookieService.delete("token");
    setAuthentication(false);
    setUser(authContentDefaults.user);
  };

  const signupHandler = () => {
    fetchInitialApis()
  }

  const loadPlugin = async () => {
    commonApi.fetchPlugins().then((res) => {
      setPlugins(res);
    }).catch((error: XMLHttpRequest) => {
      if (error.status === 401) {
        setAuthentication(false);
      }
    })
  };
  return (
    <authContext.Provider
      value={{
        user,
        onLogin: loginHandler,
        onLogout: logoutHandler,
        onSignup: signupHandler,
        getUserSettings: getSettings,
        userSettings,
        isAuthenticated,
        isAIEnabled,
        appPermission,
        isCreateMatterEnabled,
        plugins,
      }}
    >
      {isLoading ? (
        <div className="ltb-loader-page">
          <Loader></Loader>
        </div>
      ) : (
        <CFade>{children}</CFade>
      )}
    </authContext.Provider>
  );
};
export default AuthProvider;
